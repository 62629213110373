// This is a RegExp that matches the password requirements we can use in the future
// export const passwordRequirements =
//   "minimum eight characters: at least one uppercase letter, one lowercase letter, one number and one special character";
// const passwordValidator =
//   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

// export const isPasswordValid = (password: string) =>
//   passwordValidator.test(password);

const passwordTests = [
  {
    isValid: (password: string) => password.length >= 8,
    description: "minimum eight characters",
  },
];

export const isPasswordValid = (password: string) =>
  passwordTests.every((test) => test.isValid(password));

export const testPassword = (password: string) => {
  const failedTests = passwordTests.filter((test) => !test.isValid(password));
  return failedTests.map((test) => test.description);
};
