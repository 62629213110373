import { asIs, throwError } from "@dancrumb/fpish";
import { Api } from "@sutro/studio2-quarantine/sutro-api/Api";
import { useAuthenticatedSutroApi } from "@sutro/studio2-quarantine/sutro-api/api-provider";
import { SutroApi } from "@sutro/studio2-quarantine/sutro-api/index";
import { StudioHttpError } from "@sutro/studio2-quarantine/sutro-api/StudioHttpError";
import { isEmpty } from "sutro-common";
import { AuthData } from "sutro-common/auth-data";
import { StudioUserProfile } from "sutro-common/sutro-data-store-types";
import { create } from "zustand";

import { isUserLoggedIn } from "~/lib/is-user-logged-in";
import { StudioError } from "~/lib/studio-error";

import { isEmailValid } from "./is-email-valid";
import { testPassword } from "./is-password-valid";
import {
  clearAuthData,
  clearStudioUser,
  retrieveHasUserVisitedStudio,
  retrieveStudioUser,
  storeAuthData,
  storeHasUserVisitedStudio,
  storeStudioUser,
} from "./studio-local-storage";

type ProfileUpdateOutcome = {
  success: boolean;
  error?: string;
};

export type Profile = {
  currentUser: StudioUserProfile | null;
  setCurrentUser: (newUser: StudioUserProfile | null) => void;
  register: (email: string, password: string) => Promise<StudioUserProfile>;
  login: (email: string, password: string) => Promise<StudioUserProfile>;
  hasUserVisitedStudio: boolean;
  setHasUserVisitedStudio: (hasVisited: boolean) => void;
  changePassword: (password: string, newPassword: string) => Promise<AuthData>;
  requestResetPassword: (email: string) => Promise<ProfileUpdateOutcome>;
  resetPassword: (args: {
    newPassword: string;
    confirmNewPassword: string;
    token: string;
  }) => Promise<ProfileUpdateOutcome>;
  logout: () => void;
  /**
   * Some events need to be deferred until login is complete.
   *
   * This function allows us to do that
   *
   * It assumes that the event is a CustomEvent and that the provided `detail` is appropriate for the provided eventName
   */
  deferEvent: (eventName: string, detail?: unknown) => void;
  deferredEvents: ReadonlyArray<[eventName: string, detail?: unknown]>;
};

/**
 * This function just sends an event that was previously deferred
 */
const dispatchDeferredEvent = (event: string, detail?: unknown) => {
  window.dispatchEvent(new CustomEvent(event, detail ? { detail } : undefined));
};

const useProfileStore = create<
  Profile & { api: Api; anonymousApi: Api; setApi: (api: Api) => void }
>()((set, get) => ({
  api: SutroApi.getApi(),
  setApi: (api: Api) => {
    set({ api });
  },
  anonymousApi: SutroApi.getApi(),
  currentUser: retrieveStudioUser(),
  setCurrentUser: (newUser: StudioUserProfile | null) => {
    window.dispatchEvent(new CustomEvent("userChanged", { detail: newUser }));
    if (newUser) {
      storeStudioUser(newUser);
    } else {
      clearStudioUser();
    }
    set({ currentUser: newUser });
    // We hold off on replaying the deferred events until the next idle period
    // so that the event handlers have an up-to-date view of the app state
    requestIdleCallback(
      () => {
        if (newUser !== undefined) {
          get().deferredEvents.forEach(([eventName, detail]) =>
            dispatchDeferredEvent(eventName, detail)
          );
          set({ deferredEvents: [] });
        }
      },
      { timeout: 250 }
    );
  },
  hasUserVisitedStudio: retrieveHasUserVisitedStudio(),
  setHasUserVisitedStudio: (newValue: boolean) => {
    storeHasUserVisitedStudio(newValue);
    set({ hasUserVisitedStudio: newValue });
  },
  register: async (
    email: string,
    password: string
  ): Promise<StudioUserProfile> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const passwordTestResults = testPassword(password);
    if (!isEmpty(passwordTestResults)) {
      throw new StudioError("Password doesn't meet requirements", {
        context: { passwordTestResults },
      });
    }

    const result = await get().anonymousApi.post<StudioUserProfile>("/users", {
      identity: email,
      password,
    });
    return result.map(throwError, asIs);
  },
  login: async (
    email: string,
    password: string
  ): Promise<StudioUserProfile> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const result = await get().anonymousApi.post<{
      user: StudioUserProfile;
      auth: AuthData;
    }>("/users/token", {
      identity: email,
      password,
    });
    return result.map(throwError, ({ user, auth }) => {
      get().setCurrentUser(user);
      storeAuthData(auth);
      window.dispatchEvent(
        new CustomEvent("userChanged", {
          detail: user,
        })
      );
      return user;
    });
  },
  changePassword: async (
    currentPassword: string,
    newPassword: string
  ): Promise<AuthData> => {
    const passwordTestResults = testPassword(newPassword);
    if (!isEmpty(passwordTestResults)) {
      throw new StudioError("Password doesn't meet requirements", {
        context: { passwordTestResults },
      });
    }
    if (currentPassword === newPassword) {
      throw new StudioError("New Password cannot match current password");
    }
    const result = await get().api.post<AuthData>(`/users/password`, {
      currentPassword,
      newPassword,
    });
    return result.map(
      (e) => {
        if (e instanceof StudioHttpError) {
          if (e.status === 403) {
            throw new StudioError(
              "Please make sure that you entered your current password correctly"
            );
          }
        }
        throw e;
      },
      (auth) => {
        storeAuthData(auth);
        return auth;
      }
    );
  },
  requestResetPassword: async (
    email: string
  ): Promise<ProfileUpdateOutcome> => {
    if (!isEmailValid(email)) {
      throw new StudioError("Invalid email format");
    }
    const result = await get().anonymousApi.post("/users/passwordReset", {
      email,
    });
    return result.map(throwError, () => ({
      success: true,
    }));
  },
  resetPassword: async ({
    newPassword,
    confirmNewPassword,
    token,
  }): Promise<ProfileUpdateOutcome> => {
    if (newPassword !== confirmNewPassword) {
      return Promise.resolve({
        success: false,
        error: "Passwords do not match",
      });
    }

    const result = await get().anonymousApi.post<AuthData>(
      `/users/current/password`,
      {
        newPassword,
        resetPasswordToken: token,
      }
    );
    return result.map<ProfileUpdateOutcome>(
      (e) => {
        return { success: false, error: e.message };
      },
      (authData) => {
        storeAuthData(authData);
        return { success: true };
      }
    );
  },
  logout: () => {
    clearAuthData();
    clearStudioUser();
    const currentUser = get();
    window.dispatchEvent(
      new CustomEvent("userLoggedOut", { detail: currentUser })
    );
    get().setCurrentUser(null);

    // we do a reload to clear all local state
    window.location.reload();
  },
  deferredEvents: [],
  deferEvent: (eventName: string, detail?: unknown) => {
    if (isUserLoggedIn()) {
      dispatchDeferredEvent(eventName, detail ? { detail } : undefined);
    } else {
      set((state) => ({
        deferredEvents: [...state.deferredEvents, [eventName, detail]],
      }));
    }
  },
}));

export const useProfile = (): Profile => {
  const store = useProfileStore();
  const api = useAuthenticatedSutroApi();
  if (!store.api.isAuthenticated()) {
    store.setApi(api);
  }

  return store;
};
